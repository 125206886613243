import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer>
    <div class="container">
      <p>
        Have feature requests? Email me at hello [at] kylebaconcreative.com{" "}
        <br />
        &copy; 2019 Kyle Bacon Creative, LLC
      </p>
    </div>
  </footer>
)

export default Footer
