import React from "react"

const Header = () => (
  <header>
    <div class="container">
      <div class="flex__row spaceBetween">
        <div>
          <span class="logo">
            <a href="/">ErrandList</a>
          </span>
          <span class="comingSoon">Beta</span>
        </div>
        <div>
          <a
            href="https://get.errandlist.app/login"
            style={{
              fontFamily: "sans-serif",
              textDecoration: "none",
              color: "black",
            }}
          >
            Login
          </a>{" "}
          |{" "}
          <a
            href="https://get.errandlist.app/signup"
            style={{
              fontFamily: "sans-serif",
              textDecoration: "none",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Sign up
          </a>
        </div>
      </div>
    </div>
  </header>
)

export default Header
