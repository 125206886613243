import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`list`, `application`, `errand`]} />
    <section>
      <div class="container">
        <h1>A simple errand app with a twist</h1>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="flex__row">
          <div class="content__box">
            <div class="content__block">
              <h3>Hate wandering around the store looking for something?</h3>
              <p>
                Lists are organized by aisle. Users can correct the aisle if it
                is wrong so the app can learn. Dang stores are always moving
                stuff. :(
              </p>
            </div>
            <div class="content__block">
              <h3>Share in the errand love with a someone else.</h3>
              <p>
                Ask your significant other, roommate or whoever to shop or
                assign the trip to yourself.
              </p>
            </div>
          </div>
          <div class="content__box">
            <Image />
          </div>
          <div class="content__box">
            <div class="content__block">
              <h3>Schedule shopping trips ahead of time.</h3>
              <p>
                Create a predictable errand schedule at common stores you go to.
              </p>
            </div>
            <div class="content__block">
              <h3>Documentation and repeating trips.</h3>
              <p>
                Capture reciepts of the items you bought for safekeeping. Copy
                and paste the list to make life easy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
